<template>
  <div>
    <nav-bar></nav-bar>
    <tool-box></tool-box>

    <el-affix>
      <!-- 顶部菜单 -->
      <el-menu mode="horizontal" menu-trigger="hover" collapse-transition="false">
        <div class="container">
          <el-row class="new-home-top">
            <el-col :span="4">
              <span style="font-size: 20px;cursor: pointer;" @click="dialogVisible = true">
                {{ currentSpecial.title }}
                <svg-icon iconClass="jiantouarrow486"></svg-icon>
              </span>
            </el-col>
            <el-col :span="4" v-for="(artCla,index) in artClaList" :key="artCla.id">
              <span class="el-dropdown-link" @click="goNewsList(artCla.id)">
                {{ artCla.title }}
                <span style="float: right;" v-if="index < artClaList.length - 1">|</span>
              </span>
            </el-col>
          </el-row>
        </div>
      </el-menu>
    </el-affix>
    <!-- 弹框 -->
    <el-dialog title="更换考试科目" v-model="dialogVisible" width="950px">
      <div class="pop-ups-centent-main">
        <div class="pop-ups-centent" v-for="spec in specList" :key="spec.id">
          <div class="title" v-text="spec.title"></div>
          <ul>
            <li v-for="child in spec.children" :key="child.id">
              <a href="javascript:;" @click="findNewsSpecClaArticle(child)">{{ child.title }}</a>
            </li>
          </ul>
        </div>
        <div class="clear-float"></div>
      </div>
    </el-dialog>

    <div class="container">
      <el-row>
        <!-- 左侧分类 -->
        <el-col :span="12">
          <div class="new-select-left-list">
            <div class="new-select-left-list-item" v-for="artCla in artClaList" :key="artCla.id">
              <a class="new-select-left-list-main" href="javascript:;" v-text="artCla.title"></a>
              <span class="item-title" v-for="(item, index) in newsClassArticle[artCla.title]" :key="item.article_id" @click="goDetail(item)">
                <span v-if="index == 1">/</span>
                {{ item.article_title }}
              </span>
            </div>
          </div>
        </el-col>
        <!-- 右侧试卷类型 -->
        <el-col :span="12">
          <div class="new-select-right-list">
            <div class="new-select-right-list-item-twoline new-select-right-list-item new-select-right-twoline-one">
              <a href="javascript:;" @click="goExamList(3)">
                <div class="item">
                  <div class="icon"><svg-icon icon-class="bi"></svg-icon></div>
                  <div style="display: inline-block;">
                    <h3 style="margin: 11px 0 0 32px;color: #f68953;">每日一练</h3>
                    <h4 style="margin-left: 32px;color: #f68953;">强化巩固一小步</h4>
                  </div>
                </div>
              </a>
            </div>

            <div class="new-select-right-list-item-twoline new-select-right-list-item new-select-right-twoline-two">
              <a href="javascript:;" @click="goExamList(4)">
                <div class="item">
                  <div class="icon"><svg-icon icon-class="gengduo"></svg-icon></div>
                  <div style="display: inline-block;">
                    <h3 style="margin: 11px 0 0 32px;color: #5ca1ef;">章节练习</h3>
                    <h4 style="margin-left: 32px;color: #5ca1ef;">排疑解难全掌握</h4>
                  </div>
                </div>
              </a>
            </div>

            <div class="new-select-right-threeline new-select-right-list-item new-select-right-threeline-one">
              <a href="javascript:;" @click="goExamList(7)">
                <div class="item">
                  <div class="icon"><svg-icon icon-class="bijiben-copy"></svg-icon></div>
                  <div style="display: inline-block;">
                    <h3 style="margin: 11px 0 0 32px;color: #7bc4bc;">模拟试题</h3>
                    <h4 style="margin-left: 32px;color: #7bc4bc;">实战演练查遗漏</h4>
                  </div>
                </div>
              </a>
            </div>

            <div class="new-select-right-threeline new-select-right-list-item new-select-right-threeline-two">
              <a href="javascript:;" @click="goExamList(5)">
                <div class="item">
                  <div class="icon"><svg-icon icon-class="shijuan"></svg-icon></div>
                  <div style="display: inline-block;">
                    <h3 style="margin: 11px 0 0 32px;color: #7ca6cc;">往年真题</h3>
                    <h4 style="margin-left: 32px;color: #7ca6cc;">经典试题勤回顾</h4>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <!-- 广告 -->
          <div class="new-select-right-advert">
            <router-link :to="{ path: '/course/' + advertList.top.urlId }">
              <img :src="advertList.top.thumb" :title="advertList.top.title" />
            </router-link>
          </div>
        </el-col>
      </el-row>

      <!-- 考试日历 -->
      <el-row>
        <!-- 标题 -->
        <el-col>
          <div class="third">
            <svg-icon icon-class="rili"></svg-icon>
            <span style="margin-left: 20px;font-size: 21px;font-weight: 600;">考试日历</span>
          </div>
        </el-col>
        <!-- 内容 -->
        <el-col style="margin: 30px 0;">
          <el-steps align-center>
            <el-step :title="item.title" v-for="item in timeLineList" :key="item.id">
              <template #description>
                <div class="four">{{ item.time_desc }}</div>
              </template>
            </el-step>
          </el-steps>
        </el-col>
      </el-row>
    </div>

    <!-- 广告 -->
    <div class="container advert">
      <router-link :to="{ path: '/course/' + advertList.center.urlId }">
        <img :src="advertList.center.thumb" :title="advertList.center.title" />
      </router-link>
    </div>

    <!-- 资讯文章 -->
    <div class="container six">
      <!-- 左边 -->
      <div id="six-left">
        <div class="six-newInfo">最新资讯</div>
        <ul class="six-left-ul" v-if="leftArticle.length > 0">
          <li v-for="article in leftArticle" :key="article.id" @click="goDetail(article)">
            <img class="six-left-li-img" :src="article.article_thumb" />
            <div class="six-left-li-div">
              <div class="six-left-li-div-top">
                <div class="six-left-li-div-top-title" v-text="article.article_title"></div>
                <div class="six-left-li-div-top-cenent" v-text="article.article_intro"></div>
              </div>
              <div class="six-left-li-div-footer">
                <div class="six-left-li-div-footer-time">{{ article.create_time }}</div>
                <!-- <div class="six-left-li-div-footer-share">分享</div> -->
              </div>
            </div>
          </li>
        </ul>
        <el-empty v-else description="暂无资讯"></el-empty>
      </div>

      <!-- 右边 -->
      <div id="six-right">
        <div class="six-right-main2" v-for="artCla in artClaList" :key="artCla.id">
          <div class="six-newInfo">
            {{ artCla.title }}
            <a class="six-right-more" href="javascript:;" @click="goNewsList(artCla.id)">更多>></a>
          </div>
          <div v-if="rightArticle[artCla.title] && rightArticle[artCla.title].length > 0">
            <div class="six-right-two">
              <img :src="rightArticle[artCla.title][0].article_thumb" />
              <div class="six-right-two-centent"></div>
              <div class="six-right-two-centent-font" v-text="rightArticle[artCla.title][0].article_title"></div>
            </div>
            <ul class="six-right-ul">
              <li v-for="content in rightArticle[artCla.title]" :key="content.article_id" @click="goDetail(content)">
                <div class="point"></div>
                <div class="six-right-ul-centent" v-text="content.article_title"></div>
              </li>
            </ul>
          </div>
          <el-empty v-else description="暂无资讯"></el-empty>
        </div>
        <div class="six-right-advert">
          <router-link :to="{ path: '/course/' + advertList.footer.urlId }">
            <img :src="advertList.footer.thumb" :title="advertList.footer.title" />
          </router-link>
        </div>
      </div>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script>
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import SvgIcon from '@/components/svgicon'
import { getSpecList, getArtClaList, getSpecClaList, getNewestArticle, getAdvert, getSpecTimeLine } from "@/api/news"
import { setSessionStorage, getSessionStorage } from '@/utils/auth'

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
    SvgIcon
  },
  data() {
    return {
      specList: [], // 专题和二级专题
      artClaList: [], // 文章分类
      articleList: [], // 二级专题下文章
      newsClassArticle: {}, // 新闻页各分类文章
      leftArticle: [], // 左侧文章
      rightArticle: {}, // 右侧文章
      imgArticle: {}, // 图片上文章
      newestArticle: {}, // 最新资讯
      currentSpecial: {}, // 当前选中二级专题
      dialogVisible: false,// 弹窗
      timeLineList: [],// 时间线
      // 广告列表
      advertList: {
        top: { thumb: null, title: null, urlId: null },
        center: { thumb: null, title: null, urlId: null },
        footer: { thumb: null, title: null, urlId: null },
      },

    }
  },
  created() {
    this.findArtClaList()
    this.findSpecList()
    this.getAdvertList()
  },
  methods: {
    // 文章分类
    findArtClaList() {
      getArtClaList({ pid: 5 }).then(res => {
        if (res.code == 1) {
          this.artClaList = res.data
        }
      })
    },
    // 获取专题-二级专题
    findSpecList() {
      getSpecList().then(res => {
        if (res.code == 1) {
          this.specList = res.data
          const newsSpecialId = getSessionStorage('newsSpecialId')
          if (newsSpecialId) {
            this.specList.forEach(item => {
              item.children.forEach(child => {
                if (child.id == newsSpecialId) {
                  this.findNewsSpecClaArticle(child)
                  return
                }
              })
            })
          } else {
            this.findNewsSpecClaArticle(this.specList[0].children[0])
          }
        }
      })
    },
    // 新闻首页各个类型下文章（点击二级专题触发）
    findNewsSpecClaArticle(spec) {
      this.dialogVisible = false
      this.currentSpecial = spec
      setSessionStorage('newsSpecialId', spec.id)
      if (this.artClaList.length > 0) {
        this.getNewsList(spec.id)
      } else {
        setTimeout(() => {
          this.getNewsList(spec.id)
        }, 300)
      }
      this.findSpecArticleList(spec)
      this.getTimeLine()
    },
    // 二级专题下的最新文章
    findSpecArticleList(spec) {
      getNewestArticle({spec_id: spec.id}).then(res => {
        if (res.code == 1) {
          this.leftArticle = res.data
        }
      })
    },
    // 获取二级专题下每个类型的资讯文章
    getNewsList(specId) {
      for (const artCla of this.artClaList) {
        this.newsClassArticle[artCla.title] = []
        // 请求分类下文章
        getSpecClaList({spec_id: specId, pid: artCla.id, page: 1, limit: 5}).then(res => {
          if (res.code == 1 && res.data.length > 0) {
            this.newsClassArticle[artCla.title] = res.data.slice(-2)
            this.rightArticle[artCla.title] = res.data.slice(-3)
            // this.imgArticle = this.rightArticle[artCla.title][0]
          }
        })
      }
    },
    // 查看内容
    goDetail(article) {
      this.$router.push('/newsdetail/' + article.article_id)
    },
    // 进入试卷列表页面
    goExamList(type) {
      let specId = this.specList[0].id
      this.specList.forEach(item => {
        item.children.forEach(child => {
          if (child.id == this.currentSpecial.id) {
            specId = item.id
            return
          }
        })
      })
      setSessionStorage('examListParams', {
        specId: specId,
        specChildId: this.currentSpecial.id,
        type: type,
      })
      this.$router.push('/examlist')
    },
    // 跳转到列表页
    goNewsList(type) {
      this.$router.push('/newslist/' + this.currentSpecial.id + '/' + type)
    },
    // 获取时间线
    getTimeLine() {
      this.timeLineList = []
      const year = new Date().getFullYear()
      getSpecTimeLine({spec_id: this.currentSpecial.id, version_year: year}).then(res => {
        if (res.code == 1) {
          this.timeLineList = res.data
        }
      })
    },
    // 获取广告
    getAdvertList() {
      getAdvert({pid: 11}).then(res => {
        if (res.code == 1) {
          res.data.forEach(item => {
            item.urlId = item.urlId.replace(",", "/");
          })
          this.advertList.top = res.data[0]
          this.advertList.center = res.data[1]
          this.advertList.footer = res.data[2]
        }
      })
    },

  },
};
</script>

<style scoped lang="scss">
.clear-float {
  clear: both;
}
.advert {
  margin: 0 auto;
  img {
    width: 100%;
  }
}
// 顶部菜单 begin
.el-menu {
  background-color: $theme-color;
}
.new-home-top {
  color: #fff;
  width: 100%;
  height: 73px;
  display: flex;
  align-items: center;
  text-align: center;
}
.new-home-top-title {
  font-size: 21px;
}
.new-home-top:not(.no-border) {
  border-right: 2px solid #fff;
}
.new-home-top:not(.no-border) {
  border-right: 0;
}
.el-dropdown-link {
  color: #fff;
  width: 200px;
  line-height: 50px;
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
}
// 弹窗
.pop-ups-centent-main {
  padding: 25px 0;
}
.pop-ups-centent {
  display: block;
  width: 185px;
  border-right: 1px solid #dad9d9;
  float: left;
}
.pop-ups-centent:nth-last-child(2) {
  border-right: 0;
}
.pop-ups-centent > .title {
  margin-left: 42px;
  font-size: 16px;
  line-height: 18px;
  color: #272626;
}
.pop-ups-centent > .title:after {
  content: "";
  display: block;
  width: 65px;
  height: 2px;
  background-color: $theme-color;
  margin-top: 8px;
}
.pop-ups-centent ul {
  margin-left: 42px;
}
.pop-ups-centent ul li {
  font-size: 16px;
  margin-top: 21px;
  cursor: pointer;
}
// 顶部菜单 end

// 分类 begin
// 左侧分类
.new-select-left-list-main {
  line-height: 71px;
  font-size: 20px;
  padding: 0 15px;
}
.new-select-left-list-main:link {
  color: $theme-color;
}
.new-select-left-list-item {
  border-bottom: 1px solid $theme-color;
}
.new-select-left-list-item .item-title {
  color: #666;
  width: 210px;
  font-size: 17px;
  padding: 0 10px;
  margin-bottom: -4px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.new-select-left-list-item .item-title > span {
  margin-right: 8px;
}
// 右侧试卷类型
.new-select-right-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.new-select-right-list a:link {
  color: #fff;
}
.new-select-right-list-item-twoline {
  width: 250px;
  height: 61px;
  margin-top: 30px;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      padding-top: 10px;
      font-size: 30px;
    }
  }
}
.new-select-right-list-item {
  border-radius: 5px;
}
.new-select-right-twoline-one {
  border: 1px solid $warning-color;
}
.new-select-right-twoline-two {
  border: 1px solid $primary-color;
}
.new-select-right-advert {
  width: 552px;
  height: 50px;
  margin: 0 auto;
  margin-top: 50px;
  img {
    width: 100%;
    height: 50px;
  }
}
/* 右边第三行 */
.new-select-right-threeline {
  width: 250px;
  height: 61px;
  border: 1px solid $warning-color;
  border-radius: 5px;
  margin-top: 20px;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      padding-top: 10px;
      font-size: 30px;
    }
  }
}
.new-select-right-threeline-one {
  float: left;
  border: 1px solid #7bc4bc;
}
.new-select-right-threeline-two {
  float: right;
  border: 1px solid #7ca6cc;
}
// 分类 end

// 考试日历 begin
.third {
  margin-left: 34px;
  margin-top: 28px;
  font-size: 21px;
  span {
    margin-left: 20px;
    font-weight: 600;
  }
}
.four {
  // width: 128px;
  // height: 28px;
  border: 1px solid #a9a6a6;
  color: #908f8f;
  border-radius: 5px;
  font-size: 14px;
  line-height: 28px;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  // overflow: hidden;
}

.el-step__title.is-wait {
  color: #8f8f8f;
}
// 考试日历 end

// 广告 begin


// 广告 end

// 资讯文章 begin
.six {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
#six-left {
  width: 691px;
  height: auto;
  margin-top: 30px;
}
#six-right {
  width: 459px;
  height: auto;
  margin-top: 30px;
}
.six-right-main2 {
  margin-top: 35px;
  border-bottom: 2px solid #f4f4f4;
  padding-bottom: 20px;
}
.six-newInfo {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #2b2d32;
}
.six-left-ul li {
  margin: 24px 24px 0 0;
  cursor: pointer;
}
.six-left-li-img {
  width: 288px;
  height: 217px;
  border-radius: 5px;
}
.six-left-li-div {
  width: 356px;
  height: 217px;
  float: right;
}
.six-left-li-div-top-title {
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 2px;
  color: #2b2d32;
  font-weight: 600;
  width: 356px;
  height: 58px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.six-left-li-div-top-cenent {
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #2b2d32;
  margin-top: 10px;
  width: 356px;
  height: 75px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.six-left-li-div-footer {
  margin-top: 35px;
}
.six-left-li-div-footer-time {
  font-size: 14px;
  line-height: 29px;
  color: #9d9e9e;
  margin-top: 15px;
  float: left;
}
.six-left-li-div-footer-center {
  font-size: 14px;
  line-height: 29px;
  letter-spacing: 2px;
  color: #9d9e9e;
  float: left;
  margin: 15px 0 0 42px;
}
.six-left-li-div-footer-share {
  float: right;
  width: 48px;
  height: 24px;
  background: $theme-color;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #fff;
  text-align: center;
  margin-top: 17px;
  cursor: pointer;
}
/* 右边 */
.six-right-more {
  font-size: 14px;
  color: #666;
  line-height: 35px;
  float: right;
  cursor: pointer;
}
.six-right-more:link {
  color: #666;
}
.six-right-two {
  width: 461px;
  height: 258px;
  position: relative;
  margin-top: 24px;
}
.six-right-two img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.six-right-two-centent {
  width: 100%;
  height: 56px;
  background: #000;
  opacity: 0.3;
  border-radius: 0 0 4px 4px;
  position: absolute;
  bottom: 0;
}
.six-right-two-centent-font {
  width: 100%;
  height: 56px;
  position: absolute;
  bottom: 0;
  font-size: 18px;
  letter-spacing: 2px;
  color: #fff;
  line-height: 56px;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 10px;
  opacity: 1;
}
.six-right-ul {
  margin-top: 32px;
}
.six-right-ul > li {
  display: list-item;
  margin: 21px 0 21px 0;
}
.point {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: $theme-color;
  float: left;
  margin: 1px 15px;
}
.six-right-ul-centent {
  font-size: 16px;
  letter-spacing: 2px;
  color: #2a2b32;
  width: 400px;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  line-height: 16px;
}
.six-right-advert {
  img {
    height: 50px;
  }
}
// 资讯文章 end
</style>
<style>
/* 弹窗默认样式修改 */
.el-dialog {
  border-radius: 5px;
}
.el-dialog__body {
  padding: 0;
}
.el-dialog__header {
  padding: 28px 0 0 42px;
}
.el-dialog__title {
  font-size: 16px;
  line-height: 18px;
  color: #7e7c7c;
}
.el-step.is-center .el-step__description {
	padding-left: 5% !important;
	padding-right: 5% !important;
}
</style>
